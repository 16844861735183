import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { apiUrl, getRequest } from '../../utils/request';
import Button from '../form/fields/button';
import Notification from '../alerts/notification';

function MyCode() {
  const [code, setCode] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const init = async () => {
      try {
        const result = await getRequest(`${apiUrl}/dashboard/referral-code`);
        if (result && result.code && result.code.code) {
          setCode(result.code.code);
        }
        // console.log(result, 'the result');
      } catch (err) {
        console.log(err, 'error fetching referral code');
      }
    };
    init();
  }, []);

  if (!code) {
    return null;
  }


  const copied = () => {
    setMessage('Referral link copied!');
    setTimeout(() => setMessage(null), 2250);
  };

  const codeLink = `https://sidewallclub.com/?r=${code}`;

  return null;

  return (
    <div className="bg-white shadow sm:rounded-lg mb-8 border border-gray-100">
      <Notification message={message} />
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Spread the word!
        </h3>
        <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
          <p>
            Do you have friends that love a good deal?
            Share your referral link with them!
      </p>
        </div>
        <div className="mt-5">
          <p className="text-sm text-center font-base">{codeLink}</p>

          <div className="mt-5">
            <CopyToClipboard text={codeLink}>
              <button onClick={copied} className="text-white bg-gray-500 hover:bg-gray-600 border-0 py-2 px-8 focus:outline-none rounded text-lg mx-auto block">Copy Referral Link</button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyCode;
