import React, { useState, useEffect } from "react";
import { Link } from 'gatsby';
import SEO from "../../seo";
import { apiUrl, getRequest } from '../../../utils/request';
import { getCurrentUser } from '../../../utils/auth';
import Loader from '../../loader';
import AdminHeader from '../../nav/admin-header';

function DashboardStat({ title, loading, stat, linkHref, linkText }) {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg border border-gray-100">
      <div className="px-4 py-5 sm:p-6">
        <dl>
          <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
            {title}
          </dt>
          <dd className="mt-1 text-3xl leading-9 font-semibold text-gray-900">
            {loading && <div className="pt-3"><Loader /></div>}
            {!loading && stat.toLocaleString()}
          </dd>
        </dl>
      </div>
      {!!linkHref && (
        <div className="bg-gray-50 px-4 py-4 sm:px-6">
          <div className="text-sm leading-5">
            <Link to={linkHref} className="font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
              {linkText}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}


function DashboardStats() {
  const [subscriptions, setSubscriptions] = useState(0);
  const [trials, setTrials] = useState(0);
  const [trialsSold, setTrialsSold] = useState(0);
  const [users, setUsers] = useState(0);
  const [referrals, setReferrals] = useState(0);
  const [redemptions, setRedemptions] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      setLoading(true);
      const url = `${apiUrl}/admin/dashboard/stats`;
      try {
        const result = await getRequest(url);
        setSubscriptions(1 * result.subscriptions);
        setTrials(1 * result.trials);
        setTrialsSold(1 * result.trialsSold);
        setUsers(1 * result.users);
        setRedemptions(1 * result.redemptions);
        setReferrals(1 * result.referrals);
      } catch (err) {
        console.log(err, 'err');
      }
      setLoading(false);
    }
    init();
  }, []);

  return (
    <div className="w-full mb-20">
      <h3 className="text-lg leading-6 font-medium text-gray-900">By the numbers</h3>
      <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <DashboardStat
          title="Active Subscriptions"
          loading={loading}
          stat={subscriptions}
        // linkHref="/app/admin/guide/applications"
        // linkText="View All Guide Applications"
        />

        <DashboardStat
          title="Gift Memberships Sold"
          loading={loading}
          stat={trialsSold}
        // linkHref="/app/admin/guides"
        // linkText="View All Guides"
        />

        <DashboardStat
          title="Gift Memberships Claimed"
          loading={loading}
          stat={trials}
        // linkHref="/app/admin/scheduled"
        // linkText="View All Scheduled Tours"
        />

        <DashboardStat
          title="Coupons Redeemed"
          loading={loading}
          stat={redemptions}
          linkHref="/app/admin/redemptions"
          linkText="View All Redemptions"
        />

        <DashboardStat
          title="Total Users"
          loading={loading}
          stat={users}
          linkHref="/app/admin/users"
          linkText="View All Users"
        />

        <DashboardStat
          title="Total Referrals"
          loading={loading}
          stat={referrals}
          linkHref="/app/admin/top-referrers"
          linkText="View Top Referrers"
        />

        {/* <DashboardStat
          title="Early Notification Signups"
          loading={loading}
          stat={earlyNotifications}
          linkHref="/app/admin/early-notifications"
          linkText="View All Early Notifications"
        />

        <DashboardStat
          title="Favorites"
          loading={loading}
          stat={favorites}
          linkHref="/app/admin/favorites"
          linkText="View All Favorites"
        /> */}
      </div>
    </div>
  )
}

const AdminDashboard = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(getCurrentUser());
  }, []);

  let fname = "";
  if (user) {
    fname = user && user.first_name ? user.first_name : user.name;
  }
  // console.log(upcoming, 'upcom')
  const breadcrumbs = [{ label: 'Dashboard', href: '/app/dashboard' }];

  return (
    <>
      <SEO title="Dashboard" />
      <AdminHeader title={`Welcome ${fname}`} breadcrumbs={breadcrumbs} />

      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 py-12 items-center justify-center flex-col">
          <DashboardStats />
        </div>
      </section>
    </>
  );
};

export default AdminDashboard;
