import React from 'react';

function getColor(type) {
  switch (type) {
    case 'error':
    case 'danger':
      return {
        titleBg: 'bg-red-500',
        bodyBorder: 'border-red-400',
        bodyBg: 'bg-red-100',
        bodyText: 'text-red-700',
      };

    case 'success':
      return {
        titleBg: 'bg-green-500',
        bodyBorder: 'border-green-400',
        bodyBg: 'bg-green-100',
        bodyText: 'text-green-700',
      };
    case 'warning':
      return {
        titleBg: 'bg-orange-500',
        bodyBorder: 'border-orange-400',
        bodyBg: 'bg-orange-100',
        bodyText: 'text-orange-700',
      };

    case 'info':
    default:
      return {
        titleBg: 'bg-blue-500',
        bodyBorder: 'border-blue-400',
        bodyBg: 'bg-blue-100',
        bodyText: 'text-blue-700',
      };
  }
}

function Accent({ body, type }) {
  const colors = getColor(type);

  return (
    <div className={`${colors.bodyBg} border-l-4 ${colors.bodyBorder} p-4`}>
      <div className={`${colors.bodyBg}`}>
        <p className={`text-sm leading-5 ${colors.bodyText}`}>{body}</p>
      </div>
    </div>
  );
}

export default Accent;
