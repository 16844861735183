import React, { useState, useEffect } from "react";
import * as yup from 'yup';
import { Link, navigate } from 'gatsby';
import moment from 'moment';
import queryString from 'query-string';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Alert from '../../alerts/alert';
import Loader from '../../loader';
import SEO from "../../seo";
import { apiUrl, getRequest, postRequest } from '../../../utils/request';
import AdminHeader from '../../nav/admin-header';
import Table from '../../common/table';
import Pagination from "../../common/pagination";
import Form from '../../form/form';


function buildDownloadUrl(token) {
  let concatenated = `${apiUrl}/admin/redemptions/csv?token=${token}`;
  return concatenated.replace('/api/admin', '/admin');
}

const filterForm = {
  order: [
    'phone',
    'email',
    'location_id',
    'submit',
  ],
  fields: {
    location_id: {
      schema: yup.string().trim(),
      label: "Location",
      type: "select",
      options: [],
      selectText: "All",
    },
    phone: {
      schema: yup.string().trim(),
      customValidation: (field, value, values) => {
        const msg = "You must provide a valid US mobile phone number.";
        if (!value) {
          return null;
        }
        const phoneNumber = parsePhoneNumberFromString(value, 'US');
        return !phoneNumber || !phoneNumber.isValid() ? msg : null;
      },
      label: "Phone",
      type: "phone",
    },
    email: {
      schema: yup.string().email().max(255).trim(),
      label: "Email",
      type: "email",
    },
    submit: {
      type: 'button',
      submit: true,
      text: "Search",
      color: "cool-gray",
      block: true,
    },
  },
};

const Redemptions = () => {
  const [mounted, setMounted] = useState(false);
  const [locations, setLocations] = useState(null);
  const [redemptions, setRedemptions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(null);
  const [lastPage, setLastPage] = useState(1);
  const [lastParams, setLastParams] = useState('');
  const [searchValues, setSearchValues] = useState({ page: 1 });

  const maybeUpdateSearchValues = (params, force) => {
    const searchParams = params || {};
    const paramsAsString = queryString.stringify(searchParams);
    if (force || paramsAsString !== lastParams) {
      setSearchValues({ ...searchParams });
      setLastParams(paramsAsString);
    }
  };

  useEffect(() => {
    const init = async () => {
      if (!locations) {
        // Go fetch our locations before moving on...
        const url = `${apiUrl}/admin/list-locations`;
        try {
          const result = await getRequest(url);
          setLocations(result);
        } catch (err) {
          console.log(err, 'err');
          setAlert({
            type: 'error',
            body: "An error occurred, please reload the page.",
          });
        }
      }
    }

    const params = queryString.parse(window.location.search);
    maybeUpdateSearchValues(params, true);
    setMounted(true);
    init();
  }, []);

  useEffect(() => {
    if (window) {
      const params = queryString.parse(window.location.search);
      maybeUpdateSearchValues(params);
    }
  });

  useEffect(() => {
    fetch();
  }, [searchValues]);

  const updateParams = (params) => {
    navigate(`/app/admin/redemptions/?${queryString.stringify(params)}`);
  }

  const switchPage = (num) => {
    const params = {
      ...searchValues,
      page: 1 * num,
    };
    updateParams(params);
  }

  const handleSearch = (data, setErrors) => {
    const params = {
      ...data,
      page: 1,
    };
    updateParams(params);
    setErrors({});
  }

  const fetch = async function () {
    if (!mounted) {
      return;
    }
    setLoading(true);
    // console.log(page, 'the page...');
    const params = {
      ...searchValues,
      page,
    };
    const applicationUrl = `${apiUrl}/admin/redemptions?${queryString.stringify(params)}`;
    try {
      const result = await getRequest(applicationUrl);
      // Format the results for the table
      if (result && result.data) {
        const columns = [
          "Name",
          "Email",
          // "Phone",
          "Location",
          "Offer",
          "Date",
          'Check #',
          "Actions",
        ];
        // console.log(result, 'result');
        const { last_page } = result;
        setLastPage(last_page || 1);
        const rows = result.data.map(item => {
          const { id, user_id: userId, name, location_name: locationName, phone, title, email, redeemed_on: redeemed, check_number: checkNumber } = item;
          return {
            id,
            items: [
              name,
              email,
              // phone,
              locationName,
              title,
              moment.unix(1 * redeemed).format('M/D/YY h:mma'),
              checkNumber || '',
              <Link className="text-indigo-600 hover:text-indigo-900 mx-2" to={`/app/admin/user/${userId}`}>View User</Link>,
            ],
          };
        });
        setRedemptions({ columns, rows });
      }
    } catch (err) {
      console.log(err, 'err');
      setAlert({
        type: 'error',
        body: "An error occurred, please reload the page.",
      });
    }
    setLoading(false);
  };


  const downloadRedemptions = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Grab an authcode
    try {
      const tokenResult = await postRequest(`${apiUrl}/admin/one-time-download-token`);
      const { token } = tokenResult;
      window.open(buildDownloadUrl(token), '_blank');
    } catch (err) {
      console.log(err, 'err');
      setAlert({
        type: 'error',
        body: "An error occurred, please reload the page.",
      });
    }
    setLoading(false);
  }


  // console.log(upcoming, 'upcom')
  const breadcrumbs = [
    { label: 'Dashboard', href: '/app/dashboard' },
    { label: 'Redemptions', href: '/app/admin/redemptions' },
  ];

  const page = searchValues && searchValues.page ? 1 * searchValues.page : 1;

  const locationOptions = locations ? locations.map(l => {
    return {
      value: l.id,
      label: l.name,
    };
  })
    : [];

  filterForm.fields.location_id.options = locationOptions;

  return (
    <>
      <SEO title="Redemptions" />
      <AdminHeader title={`Redemptions`} breadcrumbs={breadcrumbs} />

      {alert && <Alert {...alert} />}
      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 py-12 items-center justify-center flex-col">
          <div className="flex w-full text-left flex-wrap -mx-2">
            <div className="w-full lg:w-4/5 px-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-5">Redemptions &middot; <Link to="/app/admin/rolling-redemptions" className="font-bold underline">View Rolling Redemptions</Link> &middot; <button onClick={downloadRedemptions} className="font-bold underline">Download</button></h3>
              {loading && <Loader />}
              {!loading && !redemptions && <p>There are no redemptions to display.</p>}
              {!loading && redemptions && <Table {...redemptions} />}
              {!loading && redemptions && <Pagination currentPage={page} totalPages={lastPage} switchPage={switchPage} />}
            </div>
            <div className="w-full lg:w-1/5 px-2 mt-12 lg:mt-0">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-5">Search</h3>
              <div className="bg-white shadow p-4 rounded  border border-gray-100">
                {mounted && locations && <Form defaults={searchValues} submitForm={handleSearch} form={filterForm} />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Redemptions;
