import React, { useState, useEffect } from "react";
import SEO from "../../seo";
import Alert from '../../alerts/alert';
import AdminHeader from '../../nav/admin-header';
import ManageBilling from './manage-billing';


function PastDue() {

  const breadcrumbs = [
    {
      label: "Payment",
      href: "/app/past-due",
    },
  ];

  return (
    <>
      <SEO title="Payment Past Due" />
      <AdminHeader title="Payment Past Due" breadcrumbs={breadcrumbs} />

      <section className="">
        <div className="container px-5 py-12 md:px-64 mx-auto flex flex-wrap items-center">
          <div className="flex flex-col md:mx-auto w-full f">
            <Alert title="Billing Problem" type="error" body="Your membership is currently past due.  Use the button below to manage your billing information to resume your membership." />
            <ManageBilling />
          </div>
        </div>
      </section>
    </>
  );
};

export default PastDue;
