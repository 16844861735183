import React, { useState } from 'react';
import { apiUrl, postRequest } from '../../../utils/request';
import Button from '../../form/fields/button';

function ManageBilling() {
  const [loading, setLoading] = useState(false);

  const btnPress = async () => {
    setLoading(true);
    const response = await postRequest(`${apiUrl}/subscription/billing-portal`, {});
    // console.log(response, 'the response');
    const { url } = response;
    window.location.href = url;
    setLoading(false);
  }

  return (
    <div className="bg-white shadow sm:rounded-lg mb-8 border border-gray-100">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Billing
        </h3>
        <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500">
          <p>
            Manage your subscription and billing information.
          </p>
        </div>
        <div className="mt-5">
          <Button submitting={loading} field={{ color: 'gray', text: 'Manage Subscription', centered: true }} submitForm={btnPress} />
        </div>
      </div>
    </div>
  )
}

export default ManageBilling;
