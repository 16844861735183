import React from 'react';
import CouponCard from './coupon-card';

function CouponsGrid({ coupons, afterRedemption }) {
  return (
    <div className="my-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-2 lg:max-w-none">
      {coupons.map(coupon => <CouponCard coupon={coupon} key={coupon.id} afterRedemption={afterRedemption} />)}
    </div>
  );
}

export default CouponsGrid;
