import React, { useState, useEffect } from "react";
import Loader from '../loader';
import SEO from "../seo";
import { checkClaim } from '../../utils/auth';
import UserDashboard from './dashboards/user';
import AdminDashboard from './dashboards/admin';
import ManagerDashboard from './dashboards/manager';


const Dashboard = () => {
  const [which, setWhich] = useState(null);

  useEffect(() => {
    // Determine which dashboard to show!
    if (checkClaim('admin')) {
      setWhich('admin');
    } else if (checkClaim('manager')) {
      setWhich('manager');
    } else {
      setWhich('member');
    }
  }, []);

  if (!which) {
    return (
      <>
        <SEO title="Dashboard" />
        <section className="text-gray-700 body-font">
          <div className="container px-5 pt-8 pb-24 mx-auto flex flex-col">
            <Loader />
          </div>
        </section>
      </>
    );
  }

  switch (which) {
    case 'admin':
      return <AdminDashboard />;

    case 'manager':
      return <ManagerDashboard />;

    case 'member':
    default:
      return <UserDashboard />;
  }
};

export default Dashboard;
