import React, { useState, useEffect } from "react";
import { navigate } from 'gatsby';
import queryString from 'query-string';
import Loader from '../../loader';
import SEO from "../../seo";
import { apiUrl, getRequest, postRequest } from '../../../utils/request';
import { getCurrentUser, setUser } from '../../../utils/auth';
import MyCode from '../../referrals/my-code';
import ManageBilling from '../user/manage-billing';
import Alert from '../../alerts/alert';
import Accent from '../../alerts/accent';
import CouponsGrid from '../../coupons/coupons-grid';



const UserDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [user, setLocalUser] = useState(null);
  const [alert, setAlert] = useState(null);
  const [coupons, setCoupons] = useState(null);
  const [newUser, setNewUser] = useState(false);

  const fetchCoupons = async () => {
    const couponsResult = await getRequest(`${apiUrl}/dashboard/coupons`);
    setCoupons(couponsResult.coupons);
  }


  useEffect(() => {
    setLocalUser(getCurrentUser());
    async function init() {
      const statusUrl = `${apiUrl}/subscription/status`;
      // const profileUrl = `${apiUrl}/student/profile`;
      try {
        const subscriptionResult = await getRequest(statusUrl);
        // Handles past due
        if (subscriptionResult.needsPayment && subscriptionResult.status === 'past_due') {
          navigate('/app/past-due');
          return;
        }
        // Handles non subscribed
        if (subscriptionResult.subscribed === false && !subscriptionResult.genericTrial) {
          navigate('/app/checkout');
          return;
        }

        const parsed = window.location.search ? queryString.parse(window.location.search) : {};
        if (parsed.new) {
          // This is a new user! Refresh the current user!
          const meUrl = `${apiUrl}/auth/me`;
          const u = await postRequest(meUrl, {});
          setLocalUser(u);
          setUser(u);
          setNewUser(true);
        }
        await fetchCoupons();

      } catch (err) {
        console.log(err, 'err');
        setAlert({
          type: 'error',
          body: "An error occurred loading your dashboard, please reload the page.",
        });
      }
      setLoading(false);
    }
    init();
  }, []);

  if (loading) {
    return (
      <>
        <SEO title="Dashboard" />
        {/* <AdminHeader title={`Welcome`} breadcrumbs={breadcrumbs} /> */}
        <section className="text-gray-700 body-font">
          <div className="container px-5 pt-8 pb-24 mx-auto flex flex-col">
            <Loader />
          </div>
        </section>
      </>
    );
  }

  const hasCoupons = !!(coupons && coupons.length);

  // console.log(user, 'user');
  const { first_name: firstName } = user;

  return (
    <>
      <SEO title="Dashboard" />
      {alert && <Alert {...alert} />}

      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 py-12 items-center justify-center flex-col">

          <div className="flex flex-wrap w-full -mx-2">
            <div className="w-full md:w-2/3 pl-2 pr-4">
              {newUser && (
                <div className="mb-8">
                  <Accent type="success" body={<span><span className='font-bold'>Welcome {firstName}!</span>  Thanks for joining the Sidewall Club!</span>} />
                </div>
              )}
              <div className="pb-5 border-b border-gray-200">
                {/* <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Sidewall Club Dashboard
                </h3>
                <p className="mt-2 max-w-4xl text-sm text-gray-500">Your current exclusive club member offers are below.  To redeem an offer,
                show it to your server on your mobile device.
                The server will give you the PIN to redeem your offer and then they will add your exclusive club member discount to your ticket.</p> */}


                <h3 className="text-lg leading-6 font-medium text-gray-900">Thanks for being a member of the Sidewall Club!</h3>

                <p className="mt-2 max-w-4xl text-gray-600">
                  We have been transitioning to our new system and phase 1 of this is live!
                </p>

                <p className="mt-2 max-w-4xl text-gray-600">
                Here are the highlights:
                </p>


                <p className="mt-4 max-w-4xl text-gray-600">
                1. We will have more offers that are take out and not just dine-in. And, you will be able to redeem your offer with online ordering.  The discount will be applied directly to your order at the time of purchase. 
                </p>


                <p className="mt-2 max-w-4xl text-gray-600">


                2. Your Sidewall Club is now tied directly to our POS system via your phone number.  You will no longer have to login and show an offer to our team. 
                Simply give them your phone number and your offers will be pulled up in our system and can be applied directly to your order. 

                </p>

                <p className="mt-2 max-w-4xl text-gray-600">
                  3.  Each time you place an order using your phone number you'll be building up your account in order to receive additional rewards with us. 

                </p>
                <p className="mt-4 max-w-4xl text-gray-600">
                To see your current offers please visit <a className="text-blue-600" href="https://sidewallpizza.hang.com">sidewallpizza.hang.com</a>. 
                Make sure you use your phone number assigned to your Sidewall Club account. 
                </p>
                <p className="mt-4 max-w-4xl text-gray-600">
                  Thank you so much for your support and patience with this. We've received awesome feedback so far from our members and hope to hear yours! 

                </p>
                <p className="mt-2 max-w-4xl text-gray-600">
                  Please reach out any time if you have questions.  Thanks again!
                </p>

                <p className="mt-2 max-w-4xl text-gray-600">
                  Andy O'Mara<br />
                  Andy@sidewallpizza.com<br />
                  585.414.8620
                </p>


              </div>

              {hasCoupons && <CouponsGrid coupons={coupons} afterRedemption={fetchCoupons} />}
              {!hasCoupons && <p className="text-sm text-gray-500 mb-4">You don't have any deals at the moment.</p>}
            </div>
            <div className="w-full md:w-1/3 px-2">
              <MyCode />
              <ManageBilling />
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default UserDashboard;
