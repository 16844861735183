import React, { useState } from 'react';
import { navigate } from 'gatsby';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactMarkdown from 'react-markdown';
import Button from '../form/fields/button';
import { apiUrl, postRequest } from '../../utils/request';
import Notification from '../alerts/notification';

function RedeemedStatus({ redeemed, submitting, submit, start, end, copied, code }) {
  const now = moment();
  const isBefore = now.isBefore(start, 'day');
  const isAfter = end && now.isAfter(end, 'day');
  if (redeemed) {
    return (
      <div className="w-full border-t border-gray-150 mt-4 pt-4 text-center">
        <p className="font-bold text-sm">Redeemed on {moment.unix(1 * redeemed).format('M/D/YY')}</p>
      </div>
    );
  }
  if (isBefore) {
    return (
      <div className="border-t border-gray-150 mt-4 pt-4 w-full text-center">
        <p className="font-bold text-sm">Will be available on {start.format('M/D/YY')}</p>
      </div>
    );
  }
  if (isAfter) {
    return (
      <div className="border-t border-gray-150 mt-4 pt-4 w-full text-center">
        <p className="font-bold text-sm">Expired on {end.format('M/D/YY')}</p>
      </div>
    );
  }

  const redeem = () => {
    navigate(`/coupon-redeem/?code=${code}`);
  }

  return (
    <>
      <div className="flex-none mt-6 mb-4 text-center bg-gray-50 pb-2 rounded shadow">
        <p className="font-semibold text-sm bg-red-500 text-white p-1 rounded-t mb-1">Your Code For This Offer Is</p>
        <p className="font-bold text-3xl mb-2">{code}</p>
        <div className="my-4">
          <button onClick={redeem} className="text-white bg-green-600 hover:bg-green-500 border-0 py-2 px-8 focus:outline-none rounded text-lg mx-auto block">Redeem Now</button>
        </div>
        <p className="text-gray-600 text-xs px-2">This is not a promo code that can be used with online ordering.</p>

        {/* <p className="text-gray-600 text-xs px-2">To redeem, present this code to a team member when you place your order.  This is not a promo code that can be used with online ordering.</p> */}
      </div>
      <div className="flex">
        <div className="border-t border-gray-150 mt-4 pt-4 w-full">
          <div className="flex">
            {/* <div className="flex-1 pr-1">
              <Button submitForm={submit} submitting={submitting} field={{ color: 'green', block: true, text: 'Redeem' }} />
            </div> */}
            {/* <div className="flex-1 pl-1"> */}
            <div className="flex-1">
              <CopyToClipboard text={`${process.env.GATSBY_APP_URL}/app/coupon/${code}`}>
                <button onClick={copied} className="text-white bg-blue-600 hover:bg-blue-500 border-0 py-2 px-8 focus:outline-none rounded text-lg mx-auto block">Share</button>
              </CopyToClipboard>
              {/* <Button submitForm={submit} submitting={submitting} field={{ color: 'blue', block: true, text: 'Share' }} /> */}
            </div>
          </div>
        </div >
      </div>
    </>
  );
}

function CouponCard({ coupon, afterRedemption, noAuth }) {
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const { coupon_id: couponId, title, description, image, locations, start_date: startDate, end_date: endDate, redeemed_on: redeemed, code } = coupon;

  const submit = async () => {
    setSubmitting(true);
    setError(null);
    const pin = window.prompt("A staff member will provide you with a pin # at time of order.  Please enter this pin # here.");
    if (!pin) {
      setSubmitting(false);
    } else {
      try {
        const endpoint = noAuth ? `${apiUrl}/coupon/redeem` : `${apiUrl}/dashboard/coupon/redeem`;
        await postRequest(endpoint, { pin, id: couponId });
        if (afterRedemption) {
          afterRedemption();
        }
        setSubmitting(false);
      } catch (err) {
        console.log(err, 'the error');
        setSubmitting(false);
        setError('There was an error redeeming the coupon.  Please try again.');
      }
    }
  };

  const copied = () => {
    setMessage('Coupon link copied!');
    setTimeout(() => setMessage(null), 2250);
  };

  const startDateMoment = moment(startDate);
  const endDateMoment = endDate ? moment(endDate) : null;
  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden border border-gray-100">
      <Notification message={message} />
      <div className="flex-shrink-0">
        {!!image && <img className="bg-white w-full object-cover" src={image} alt={title} />}
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-grow">
          <p className="text-xl md:text-2xl font-semibold text-gray-900 text-center">
            {title}
          </p>
          <div className="mt-4 text-base text-gray-500">
            <ReactMarkdown className="has-markdown-inside">
              {description}
            </ReactMarkdown>
          </div>
        </div>
        {/* <div className="mt-6 flex items-center">
          <div className="flex-shrink-0 text-sm, text-gray-600">
            <p className="font-semibold mb-1">Available At:</p>
            <p>
              {locations.map((location, i) => {
                return (
                  <span key={location.id}>
                    {i > 0 && ', '}
                    {location.name}
                  </span>
                );
              })}
            </p>
          </div>
        </div> */}
        <div className="flex-none">

          <p className="text-sm font-semibold text-gray-800 mt-8 text-center">
            {!!endDate && <>Available {startDateMoment.format('M/D/YY')} thru {moment(endDate).format('M/D/YY')}</>}
            <br />Valid as long as your club membership is active
          </p>
        </div>
        <RedeemedStatus copied={copied} code={code} submit={submit} submitting={submitting} redeemed={redeemed} start={startDateMoment} end={endDateMoment} />
        {!!error && <div className="flex"><p className="text-red-600 my-2 text-sm">{error}</p></div>}
      </div >
    </div >
  );
}

export default CouponCard;
