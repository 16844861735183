import React from 'react';
import { Link } from 'gatsby';

function Breadcrumbs({ items }) {
  if (!items || !items.length) {
    return null;
  }
  const backLink = items.length > 1 ? items[items.length - 2] : null;

  return (
    <div>
      {backLink && (
        <nav className="sm:hidden">
          <Link to={backLink.href} className="flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">
            <svg className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
            </svg>
            Back
          </Link>
        </nav>
      )}
      <nav className="hidden sm:flex items-center text-sm leading-5 font-medium">
        {items.map((item, i) => {
          const isFirst = i === 0;
          return (
            <React.Fragment key={item.href}>
              {!isFirst && (
                <svg className="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
              )}
              <Link to={item.href} className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">{item.label}</Link>
            </React.Fragment>
          )
        })}
      </nav>
    </div>
  );

}


export default Breadcrumbs;
