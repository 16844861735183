import React, { useState, useEffect } from "react";
import Alert from '../../alerts/alert';
import Loader from '../../loader';
import SEO from "../../seo";
import { apiUrl, getRequest } from '../../../utils/request';
import AdminHeader from '../../nav/admin-header';
import Table from '../../common/table';
import Nl2br from '../../common/nl2br';


const TopReferrers = () => {
  const [referrers, setReferrers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    fetchReferrers();
  }, []);

  const fetchReferrers = async function () {
    setLoading(true);
    const applicationUrl = `${apiUrl}/admin/top-referrers`;
    try {
      const result = await getRequest(applicationUrl);
      // Format the results for the table
      if (result && result.data) {
        const columns = [
          "Info",
          "# Referrals",
        ];
        // console.log(result, 'result');
        const { last_page } = result;
        const rows = result.data.map(item => {
          const { id, num, name } = item;
          return {
            id,
            items: [
              <Nl2br str={name} />,
              num,
            ],
          };
        });
        setReferrers({ columns, rows });
      }
    } catch (err) {
      console.log(err, 'err');
      setAlert({
        type: 'error',
        body: "An error occurred, please reload the page.",
      });
    }
    setLoading(false);
  };

  // console.log(upcoming, 'upcom')
  const breadcrumbs = [
    { label: 'Dashboard', href: '/app/dashboard' },
    { label: 'Top Referrers', href: '/app/admin/top-referrers' },
  ];

  return (
    <>
      <SEO title="Top Referrers" />
      <AdminHeader title={`Top Referrers`} breadcrumbs={breadcrumbs} />

      {alert && <Alert {...alert} />}
      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 py-12 items-center justify-center flex-col">
          <div className="flex w-full text-left flex-wrap -mx-2">
            <div className="w-full md:w-1/2 mx-auto px-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-5">Top Referrers</h3>
              {loading && <Loader />}
              {!loading && !referrers && <p>There are no referrers to display.</p>}
              {!loading && referrers && <Table {...referrers} />}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TopReferrers;
