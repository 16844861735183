import React, { useState, useEffect } from "react";
import SEO from "../../seo";
import { getCurrentUser } from '../../../utils/auth';
import AdminHeader from '../../nav/admin-header';
import RollingData from '../../redemptions/rolling-data';

const ManagerDashboard = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(getCurrentUser());
  }, []);

  let fname = "";
  if (user) {
    fname = user && user.first_name ? user.first_name : user.name;
  }
  // console.log(upcoming, 'upcom')
  const breadcrumbs = [{ label: 'Dashboard', href: '/app/dashboard' }];

  return (
    <>
      <SEO title="Dashboard" />
      <AdminHeader title={`Welcome ${fname}`} breadcrumbs={breadcrumbs} />

      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 md:px-48 lg:px-64 py-12 items-center justify-center flex-col">
          <RollingData />
        </div>
      </section>
    </>
  );
};

export default ManagerDashboard;
