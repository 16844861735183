import React, { useState, useEffect } from "react";
import moment from 'moment';
import { navigate, Link } from 'gatsby';
import Alert from '../../alerts/alert';
import Loader from '../../loader';
import SEO from "../../seo";
import { apiUrl, getRequest, postRequest } from '../../../utils/request';
import AdminHeader from '../../nav/admin-header';
import DataRow from '../../common/datalist/row';
import Nl2br from '../../common/nl2br';
import FormButton from '../../form/fields/button';
import { logout, setJwt, setUser } from '../../../utils/auth';
import Table from "../../common/table";

function ucwords(str) {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
}

function UserDisplay({ user, status, redemptions }) {
  // console.log(user, 'this is the user');
  const { email, phone, role, name, avatar, timezone, created_at: createdAt, id, city, state, zip } = user;
  const { status: subscriptionStatus } = status;

  let redemptionsDisplay = 'This user has not redeemed any coupons yet.';
  if (redemptions && redemptions.length) {
    const formatted = {
      columns: [
        'Location',
        'Offer',
        'Date',
      ],
    };
    formatted.rows = redemptions.map(redemption => {
      const { id, title, location_name: locationName, redeemed_on: redeemedOn } = redemption;
      return {
        id,
        items: [
          locationName,
          title,
          moment.unix(1 * redeemedOn).format('M/D/YY h:mma'),
        ],
      };
    });
    redemptionsDisplay = <Table {...formatted} />;
  }

  const data = {
    name,
    email,
    phone,
    city,
    state,
    zip,
    role: role.name,
    // roleName: translateRoleIdToName(role.id),
    subscriptionStatus: ucwords(subscriptionStatus),
    redemptions: redemptionsDisplay,
  };

  const order = [
    {
      field: 'name',
      label: "Name",
    },
    {
      field: 'subscriptionStatus',
      label: "Subscription Status",
    },
    {
      field: 'role',
      label: 'Role',
    },
    {
      field: 'email',
      label: "Email",
    },
    {
      field: 'phone',
      label: "Phone",
    },
    {
      field: 'city',
      label: "City",
    },
    {
      field: 'state',
      label: "State",
    },
    {
      field: 'zip',
      label: "Zip",
    },
    {
      field: 'redemptions',
      label: "Redemptions",
    },
  ];

  return (
    <div className="bg-white shadow overflow-hidden border border-gray-100 sm:rounded-lg">
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6 flex flex-wrap">
        <div className="w-2/3">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            User Details
          </h3>

          <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
            {moment(createdAt).format('M/D/Y h:mma')}
          </p>
        </div>
        <div className="w-1/3 text-right">
          <Link className="text-indigo-600 hover:text-indigo-900" to={`/app/admin/user/${id}/edit`}>Edit</Link>
        </div>
      </div>
      <div className="px-4 py-5 sm:p-0">
        <dl>
          {order.map((item, i) => {
            const value = data[item.field];
            return <DataRow key={item.field} label={item.label} value={<Nl2br str={value} />} first={i === 0} />
          })}
        </dl>
      </div>
    </div>
  );
}





function LARPButton({ id, setAlert }) {
  const [loading, setLoading] = useState(false);

  const loginAsUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    // try {
    //   const result = await postRequest(`${apiUrl}/admin/user/${id}/password-reset`, {});
    //   if (result.status === 'success') {
    //     setAlert({
    //       type: 'success',
    //       body: "A new password reset email was sent to the user.",
    //     });
    //   }
    // } catch (err) {
    //   console.log(err, 'error resetting password');
    // }

    const url = `${apiUrl}/admin/user/auth/${id}`;
    const meUrl = `${apiUrl}/auth/me`;
    try {
      const result = await postRequest(url, {});
      // Logout existing user
      logout();
      setJwt(result.access_token);
      const user = await postRequest(meUrl, {});
      setUser(user);
      const redir = '/app/dashboard';
      navigate(redir);
    } catch (err) {
      setAlert({
        type: 'error',
        body: "There was an error authenticating as the user.",
      });
    }
    setLoading(false);
  }

  if (loading) {
    return (
      <>
        <SEO title="User" />
        <section className="text-gray-700 body-font">
          <div className="container px-5 pt-8 pb-24 mx-auto flex flex-col">
            <Loader />
          </div>
        </section>
      </>
    );
  }

  return (
    <div className="relative w-full py-4 px-2 rounded-md shadow-sm text-left bg-white border border-gray-100">
      <FormButton
        submitForm={loginAsUser}
        submitting={loading}
        field={{ text: "Login As This User", color: 'blue', block: true }}
      />
    </div>
  )
}

const User = ({ id }) => {
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState(null);
  const [redemptions, setRedemptions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(null);

  const name = user ? user.name : 'User';

  useEffect(() => {
    async function fetchUser() {
      setLoading(true);
      const url = `${apiUrl}/admin/user/${id}`;
      try {
        const result = await getRequest(url);
        setUser(result.user);
        setStatus(result.status);
        setRedemptions(result.redemptions);
        // console.log(result, 'result');
      } catch (err) {
        console.log(err, 'err');
        setAlert({
          type: 'error',
          body: "An error occurred loading the user, please reload the page.",
        });
      }
      setLoading(false);
    }
    fetchUser();
  }, []);



  if (loading) {
    return (
      <>
        <SEO title="User" />
        <section className="text-gray-700 body-font">
          <div className="container px-5 pt-8 pb-24 mx-auto flex flex-col">
            <Loader />
          </div>
        </section>
      </>
    );
  }

  // console.log(upcoming, 'upcom')
  const breadcrumbs = [
    {
      label: "Dashboard",
      href: "/app/dashboard",
    },
    {
      label: 'Users',
      href: '/app/admin/users'
    },
    {
      label: "User",
      href: `/app/admin/user/${id}`,
    },
  ];

  return (
    <>
      <SEO title="User" />
      <AdminHeader title={name} breadcrumbs={breadcrumbs} />

      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 py-12 items-center justify-center flex-col">
          {alert && <Alert {...alert} />}
          <div className="flex flex-wrap -mx-2 w-full">
            <div className="w-full md:w-2/3 px-2">
              <UserDisplay user={user} status={status} redemptions={redemptions} />
            </div>
            <div className="w-full md:w-1/3 px-2">
              <div className="mx-0 md:mx-4 mt-8 md:mt-0 mb-8">
                <LARPButton id={id} setAlert={setAlert} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default User;
