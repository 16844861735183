import React, { useState, useEffect } from "react";
import { navigate } from 'gatsby';
import SEO from "../seo";
import Loader from '../loader';
import Alert from '../alerts/alert';
import CheckoutForm from '../stripe/checkout-form';
import { apiUrl, postRequest, getRequest } from '../../utils/request';


const Checkout = () => {
  const [alert, setAlert] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      const statusUrl = `${apiUrl}/subscription/status`;
      try {
        const subscriptionResult = await getRequest(statusUrl);
        // WE ARE NOT ALLOWED ON THIS PAGE IF WE HAVE A SUBSCRIPTION ALREADY!
        if (subscriptionResult.subscribed !== false) {
          navigate('/app/dashboard');
        } else {
          setLoading(false);
        }
      } catch (err) {
        console.log(err, 'error attempting to check subscription status');
        setAlert({
          type: 'error',
          body: 'There was an error verifying your subscription status.  Please reload the page.',
        });
      }
    }
    init();
  }, []);



  const handleError = (error) => {
    // console.log(error, 'this is the error');
    setAlert({
      type: 'error',
      body: error.message,
    });
  };


  const handleSuccess = async (data) => {
    setSubmitting(true);
    setAlert(null);

    const { paymentMethod } = data;

    // Basic validation 
    if (!paymentMethod || !paymentMethod.id) {
      setAlert({
        type: 'error',
        body: "Something went wrong processing your payment, please try again.",
      });
      setSubmitting(false);
      return;
    }

    const genericError = "An unknown error has occurred, you have not been charged.  Please try again.";
    // POST TO THE SERVER!!!
    const url = `${apiUrl}/subscription/new`;
    try {
      const result = await postRequest(url, data);
      if (result && result.status === 'success') {
        // console.log(result, 'the result');
        navigate(`/app/dashboard?new=1`);
      } else {
        const msg = result && result.message ? result.message : genericError;
        setAlert({
          type: 'error',
          body: msg,
        });
        setSubmitting(false);
      }
    } catch (err) {
      let message = genericError;
      if (err && err.response && err.response.data && err.response.data.message) {
        message = err.response.data.message;
      }
      setAlert({
        type: 'error',
        body: message,
      });
      window.scrollTo(0, 0);
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <>
        <SEO title="Start Subscription" />
        <section className="">
          <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
            <div className="xl:w-2/6 lg:w-1/2 md:w-1/2 flex flex-col md:mx-auto w-full">
              <Loader />
            </div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <SEO title="Start Subscription" />
      <section className="">
        <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
          <div className="xl:w-2/6 lg:w-1/2 md:w-1/2 flex flex-col md:mx-auto w-full">
            {alert && <Alert {...alert} />}
            <div>
              <CheckoutForm
                handleError={handleError}
                handleSuccess={handleSuccess}
                disablePayment={submitting}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
