import React, { useState, useEffect } from "react";
import * as yup from 'yup';
import { navigate } from 'gatsby';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Alert from '../../alerts/alert';
import Loader from '../../loader';
import SEO from "../../seo";
import { apiUrl, getRequest, postRequest } from '../../../utils/request';
import { setPageAlert } from '../../../utils/page-alerts';
import { translateRoleIdToName } from '../../../utils/auth';
import AdminHeader from '../../nav/admin-header';
import { stateOptions } from '../../../forms/state-options';
import Form from '../../form/form';

const userEditForm = {
  order: [
    'first_name',
    'last_name',
    'phone',
    'email',
    'city',
    'state',
    'zip',
    'role_id',
    'submit',
  ],
  fields: {
    role_id: {
      schema: yup.string().required().trim(),
      label: "Role",
      type: "select",
      options: [
        { value: "1", label: translateRoleIdToName(1) },
        { value: "2", label: translateRoleIdToName(2) },
        { value: "3", label: translateRoleIdToName(3) },
      ],
    },
    first_name: {
      schema: yup.string().max(255).required().trim(),
      label: "First Name",
      type: "text",
    },
    last_name: {
      schema: yup.string().max(255).required().trim(),
      label: "Last Name",
      type: "text",
    },
    phone: {
      schema: yup.string().required().trim(),
      customValidation: (field, value, values) => {
        const msg = "You must provide a valid US mobile phone number.";
        if (!value) {
          return msg;
        }
        const phoneNumber = parsePhoneNumberFromString(value, 'US');
        return !phoneNumber || !phoneNumber.isValid() ? msg : null;
      },
      label: "Phone #",
      type: "phone",
    },
    email: {
      schema: yup.string().email().required().max(255).trim(),
      label: "Email",
      type: "email",
      // placeholder: "e.g. steve@aol.com",
      // helptext: "You will use this email address to login.",
    },
    city: {
      schema: yup.string().max(255).required().trim(),
      label: "City",
      type: "text",
    },
    state: {
      schema: yup.string().max(255).required().trim(),
      label: "State",
      type: "select",
      options: stateOptions(),
    },
    zip: {
      schema: yup.string().max(25).required().trim(),
      label: "Zip",
      type: "text",
    },
    submit: {
      type: 'button',
      submit: true,
      text: 'Update User',
      color: "blue",
      block: true,
    },
  },
};

const UserEdit = ({ id }) => {
  const [defaults, setDefaults] = useState({});
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    async function fetchUser() {
      setLoading(true);
      const url = `${apiUrl}/admin/user/${id}`;
      try {
        const result = await getRequest(url);
        const { user } = result;
        setDefaults({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          city: user.city,
          state: user.state,
          zip: user.zip,
          phone: user.phone,
          role_id: user.role_id,
        });
      } catch (err) {
        console.log(err, 'err');
        setAlert({
          type: 'error',
          body: "An error occurred loading the user, please reload the page.",
        });
      }
      setLoading(false);
    }
    fetchUser();
  }, []);

  const submitForm = async (data, setErrors) => {
    setAlert(null);
    try {
      const url = `${apiUrl}/admin/user/${id}/edit`;
      const response = await postRequest(url, data);
      if (response.status === 'error') {
        setAlert({
          type: 'error',
          body: response.message || "There was an unknown error, please try again.",
        });
        setErrors({});
      }
      else {
        setPageAlert({
          type: 'success',
          title: 'Success!',
          body: "The account was successfully saved.",
        });
        navigate(`/app/admin/user/${id}`);
      }
    } catch (err) {
      if (err && err.response && err.response.status === 422) {
        setErrors({ ...err.response.data.errors });
      }
      else {
        setAlert({
          type: 'error',
          body: "There was an unknown error, please try again.",
        });
        setErrors({});
      }
    }
  };

  if (loading) {
    return (
      <>
        <SEO title="Edit User" />
        <section className="text-gray-700 body-font">
          <div className="container px-5 pt-8 pb-24 mx-auto flex flex-col">
            <Loader />
          </div>
        </section>
      </>
    );
  }

  // console.log(upcoming, 'upcom')
  const breadcrumbs = [
    {
      label: "Dashboard",
      href: "/app/dashboard",
    },
    {
      label: 'Users',
      href: '/app/admin/users'
    },
    {
      label: "User",
      href: `/app/admin/user/${id}`,
    },
    {
      label: "Edit User",
      href: `/app/admin/user/${id}/edit`,
    },
  ];

  return (
    <>
      <SEO title="Edit User" />
      <AdminHeader title="Edit User" breadcrumbs={breadcrumbs} />

      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 py-12 items-center justify-center flex-col">
          {alert && <Alert {...alert} />}
          <div className="flex flex-wrap -mx-2 w-full">
            <div className="w-full md:w-1/2 mx-auto px-2">
              <Form defaults={defaults} submitForm={submitForm} form={userEditForm} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserEdit;
