import React, { useState, useEffect } from "react";
import { navigate } from 'gatsby';
import Loader from '../loader';
import SEO from "../seo";
import { apiUrl, getRequest } from '../../utils/request';
import CouponCard from './coupon-card';


function CouponPage({ code }) {
  const [coupon, setCoupon] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadCoupon = async () => {
    setLoading(true);
    try {
      const result = await getRequest(`${apiUrl}/coupon/${code}`);
      if (result && result.coupon) {
        setCoupon(result.coupon);
      } else {
        navigate('/404');
        return;
      }
    } catch (err) {
      console.log(err, 'error fetching referral code');
    }
    setLoading(false);
  };

  useEffect(() => {
    // Fetch the coupon by the code
    loadCoupon();
  }, []);


  if (loading) {
    return (
      <>
        <SEO title="Coupon" />
        <section className="text-gray-700 body-font">
          <div className="container px-5 pt-8 pb-24 mx-auto flex flex-col">
            <Loader />
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <SEO title="Coupon" />
      <section className="text-gray-700 body-font">
        <div className="container max-w-md px-5 pt-8 pb-24 mx-auto flex flex-col">
          <CouponCard coupon={coupon} afterRedemption={loadCoupon} noAuth />
        </div>
      </section>
    </>
  );
}

export default CouponPage;
