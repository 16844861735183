import React from "react";
import { navigate } from "gatsby";
import { isLoggedIn, checkClaim } from "../utils/auth";
import { setPageAlert } from '../utils/page-alerts';

const PrivateRoute = ({ component: Component, location, claims, ...rest }) => {
  if (!isLoggedIn() && ['/signin', 'signin-verify'].indexOf(location.pathname) === -1) {
    // If we’re not logged in, redirect to the login page.
    setPageAlert({
      type: 'info',
      body: "You must login or register to continue.",
    });
    navigate(`/signin?redir=${location.pathname}`, { replace: true });
    // navigate(`/signin`);
    return null;
  }
  if (claims && !checkClaim(claims)) {
    return (
      <section className="">
        <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
          <div className="lg:w-2/6 md:w-1/2 flex text-center flex-col md:mx-auto w-full f">
            <h1 className="text-lg text-gray-700">Permission Denied.</h1>
          </div>
        </div>
      </section>
    );
  }

  return <Component {...rest} />;
}


export default PrivateRoute