import React from 'react';


function TableRow({ items, rowId }) {
  const baseClasses = `px-6 py-4 whitespace-nowrap text-sm leading-5`;
  const firstClasses = `${baseClasses} font-medium text-gray-900`;
  const lastClasses = `${baseClasses} font-medium text-right`;
  const normalClasses = `${baseClasses} text-gray-500`;
  return (
    <>
      {items.map((item, i) => {
        const k = `${rowId}_${i}`;
        const first = i === 0;
        const last = i === (items.length - 1);
        let className = normalClasses;
        if (first) {
          className = firstClasses;
        } else if (last) {
          className = lastClasses;
        }
        return (
          <td className={className} key={k}>
            {item}
          </td>
        );
      })}
    </>
  )
}


function Table({ rows, columns }) {

  if (!rows || !rows.length) {
    return (
      <div className="flex flex-col">
        <p className="text-gray-700">There is no data to display.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                {columns.map((item, i) => {
                  const last = columns.length === (i + 1);
                  const className = last ? 'px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider' : 'px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider';
                  return <td key={item} className={className}>{item}</td>;
                })}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, i) => {
                const rowClass = i % 2 ? 'bg-gray-50' : 'bg-white';
                return (
                  <tr className={rowClass} key={row.id}>
                    <TableRow items={row.items} rowId={row.id} />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Table;
