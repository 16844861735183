import React, { useState } from 'react';
import * as yup from 'yup';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Colors from '../../utils/colors';
import Form from '../form/form';
import { stateOptions } from '../../forms/state-options';

const color = Colors.inputColors();


const CheckoutFormDef = {
  order: [
    'first_name',
    'last_name',
    'email',
    'city',
    'state',
    'zip',
    'confirm',
    'pay',
    'submit',
  ],
  fields: {
    first_name: {
      schema: yup.string().max(255).required().trim(),
      label: "First Name",
      type: "text",
    },
    last_name: {
      schema: yup.string().max(255).required().trim(),
      label: "Last Name",
      type: "text",
    },
    email: {
      schema: yup.string().email().required().max(255).trim(),
      label: "Email",
      type: "email",
      // placeholder: "e.g. steve@aol.com",
      // helptext: "You will use this email address to login.",
    },
    city: {
      schema: yup.string().max(255).required().trim(),
      label: "City",
      type: "text",
    },
    state: {
      schema: yup.string().max(255).required().trim(),
      label: "State",
      type: "select",
      options: stateOptions(),
    },
    zip: {
      schema: yup.string().max(25).required().trim(),
      label: "Zip",
      type: "text",
    },
    confirm: {
      schema: yup.boolean().required(),
      label: 'I understand that my $5 monthly club membership fee is a recurring charge.  I also understand that I am going to receive massive value for my $5 membership.  But, I understand that, if I am ever unhappy with the Sidewall Club, I can cancel my membership at any time.',
      type: "checkbox",
      customValidation: (field, value, values) => {
        return !value ? "This is a required field" : null;
      },
    },
    pay: {
      type: 'custom',
      name: 'pay',
      render: () => {
        return <CardElement className="mt-4 mb-8 bg-white py-3 pl-2 border-gray-300 border rounded-lg shadow" />;
      }
    },
    submit: {
      type: 'button',
      submit: true,
      text: 'Join Now!',
      color: "blue",
      block: true,
    },
  },
};



const CheckoutForm = ({ handleSuccess, handleError, disablePayment }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);

  let buttonClasses = `text-white ${color.buttonBg} ${color.buttonHoverBg} border-0 py-2 px-8 focus:outline-none rounded text-lg w-full`;

  const disabled = !stripe || submitting || disablePayment;

  if (disabled) {
    buttonClasses = `${buttonClasses} opacity-50 cursor-not-allowed`;
  }

  const handleSubmit = async (values, setErrors) => {
    setSubmitting(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setSubmitting(false);
      return;
    }

    // Check to be sure that we have the required data

    // Get a reference to a mounted CardElement.
    const cardElement = elements.getElement(CardElement);

    const { email, first_name: firstName, last_name: lastName } = values;
    const name = `${firstName} ${lastName}`;

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: { email, name },
    });

    if (error) {
      // Here we call the handleError function, with the error
      handleError(error);
      setErrors({});
      setSubmitting(false);
      // console.log('[error]', error);
    } else {
      await handleSuccess({ ...values, paymentMethod });
      setErrors({});
      setSubmitting(false);
      // Here is where we call the handleSuccess function, passing along the payment method
      // console.log('[PaymentMethod]', paymentMethod);
    }
  };


  return (
    <div className="rounded overflow-hidden shadow-lg">
      <div className="m-4">
        <h2 className="text-2xl font-semibold text-gray-700 mb-3 font-headline">Join the Sidewall Club!</h2>
        <p className="text-lg text-gray-700 mb-4">Your 3 amazing bonuses will be immediately available to you after you complete the form below.</p>
        <Form defaults={{ state: 'South Carolina' }} submitForm={handleSubmit} form={CheckoutFormDef} />
      </div>
    </div>

  );
};

export default CheckoutForm;