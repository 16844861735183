import React, { useState, useEffect } from "react";
import * as yup from 'yup';
import moment from 'moment';
import { apiUrl, getRequest } from '../../utils/request';
import Loader from '../loader';
import Alert from '../alerts/alert';
import Form from '../form/form';


const filterForm = {
  order: [
    'location_id',
    'submit',
  ],
  fields: {
    location_id: {
      schema: yup.string().trim(),
      label: "Choose Location",
      type: "select",
      options: [],
      selectText: "Select...",
    },
    submit: {
      type: 'button',
      submit: true,
      text: "Filter",
      color: "cool-gray",
      block: true,
    },
  },
};


function RollingData() {
  const [data, setData] = useState(null);
  const [locations, setLocations] = useState(null);
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(null);

  const fetchRecords = async (locationId) => {
    setLoading(true);
    const url = `${apiUrl}/admin/redemptions/rolling${locationId ? `?location_id=${locationId}` : ``}`;
    try {
      const result = await getRequest(url);
      if (result && result.length) {
        // Format the data!
        const newData = {};
        result.forEach(item => {
          const { id, code, location_name: locationName, redeemed_on: redeemedOn, title } = item;
          const t = moment.unix(redeemedOn).format('M/D/YY');
          if (!newData[t]) {
            newData[t] = {};
          }
          if (!newData[t][title]) {
            newData[t][title] = [];
          }
          newData[t][title].push({
            id,
            code,
            locationName,
          });
        });
        setData(newData);
      } else {
        setData(null);
      }
    } catch (err) {
      console.log(err, 'error fetching data');
      setAlert({
        type: 'error',
        body: "An error occurred, please reload the page.",
      });
    }
    setLoading(false);
  }

  useEffect(() => {
    async function init() {
      setLoading(true);
      if (!locations) {
        // Go fetch our locations before moving on...
        const url = `${apiUrl}/admin/list-locations`;
        try {
          const result = await getRequest(url);
          setLocations(result);
        } catch (err) {
          console.log(err, 'err');
          setAlert({
            type: 'error',
            body: "An error occurred, please reload the page.",
          });
        }
      }
      setLoading(false);
      // fetchRecords();
    }
    init();
  }, []);

  const handleSearch = (data, setErrors) => {
    setLocation(data.location_id || null);
    fetchRecords(data.location_id);
    setErrors({});
  }

  const locationOptions = locations ? locations.map(l => {
    return {
      value: l.id,
      label: l.name,
    };
  }) : [];

  filterForm.fields.location_id.options = locationOptions;

  return (
    <div className="w-full mb-20">
      {alert && <Alert {...alert} />}
      <Form defaults={{ location_id: location }} submitForm={handleSearch} form={filterForm} />
      <h3 className="text-2xl leading-6 font-medium text-gray-900 pt-8 mb-8">Recent Redemption Data</h3>
      {loading && <Loader />}
      {!loading && !location && <p className="mb-8 text-gray-700">Choose a location to get started.</p>}
      {!loading && !!location && !data && <p className="mb-8 text-gray-700">There are no redemptions to display.</p>}
      {!loading && !!data && Object.keys(data).map(dateKey => {
        return (
          <div className="mb-8 p-4 shadow border-gray-100 border rounded" key={dateKey}>
            <h3 className="text-2xl mb-4">{dateKey}</h3>
            {Object.keys(data[dateKey]).map(titleKey => {
              return (
                <div className="mb-6" key={titleKey}>
                  <h3 className="font-extrabold text-lg mb-2">{titleKey} ({data[dateKey][titleKey].length})</h3>
                  {data[dateKey][titleKey].map(item => {
                    return (
                      <div className="ml-4 mb-2" key={item.id}>{item.code} {!location && `(${item.locationName})`}</div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  )
}

export default RollingData;
