import React, { useState } from "react";
import { navigate } from 'gatsby';
import * as yup from 'yup';
// import Loader from '../loader';
import SEO from "../seo";
import { stateOptions } from '../../forms/state-options';
import Form from '../form/form';
import Alert from '../alerts/alert';
import { apiUrl, postRequest } from '../../utils/request';

const redeemForm = {
  order: [
    'code',
    'first_name',
    'last_name',
    'email',
    'city',
    'state',
    'zip',
    'submit',
  ],
  fields: {
    code: {
      schema: yup.string().max(255).required().trim(),
      label: "Your Gift Code",
      type: "text",
    },
    first_name: {
      schema: yup.string().max(255).required().trim(),
      label: "First Name",
      type: "text",
    },
    last_name: {
      schema: yup.string().max(255).required().trim(),
      label: "Last Name",
      type: "text",
    },
    email: {
      schema: yup.string().email().required().max(255).trim(),
      label: "Email",
      type: "email",
      // placeholder: "e.g. steve@aol.com",
      // helptext: "You will use this email address to login.",
    },
    city: {
      schema: yup.string().max(255).required().trim(),
      label: "City",
      type: "text",
    },
    state: {
      schema: yup.string().max(255).required().trim(),
      label: "State",
      type: "select",
      options: stateOptions(),
    },
    zip: {
      schema: yup.string().max(25).required().trim(),
      label: "Zip",
      type: "text",
    },
    submit: {
      type: 'button',
      submit: true,
      text: 'Join Now!',
      color: "blue",
      block: true,
    },
  },
}


const RedeemGiftCode = () => {
  const [alert, setAlert] = useState(null);

  const submitForm = async (data, setErrors) => {
    setAlert(null);
    const url = `${apiUrl}/gift/redeem`;
    try {
      const result = await postRequest(url, data);
      if (result && result.status === 'success') {
        const redir = '/app/dashboard?new=1';
        navigate(redir);
      }
      else {
        const msg = result && result.message ? result.message : 'There was an error processing your request, please try again.';
        setAlert({
          type: 'error',
          body: msg,
        });
        setErrors({});
      }
    } catch (err) {
      console.log(err, 'err');
      setErrors(err);
    }
  }

  const paragraphClasses = 'text-lg md:text-xl text-gray-700 leading-8 mb-8';
  const containerClasses = 'container px-2 mx-auto flex flex-wrap items-center';
  const widthClasses = 'lg:w-1/2 md:w-2/3 flex flex-col md:mx-auto w-full';
  const headlineCLasses = 'text-center text-3xl md:text-4xl leading-9 font-bold text-gray-900 mb-8 font-headline';
  // const subHeadlingClasses = 'text-center text-2xl md:text-2xl leading-9 font-bold text-gray-900 mb-8 font-headline';

  return (
    <>
      <SEO title="Redeem Gift Code" />
      <section className="bg-white pt-6 px-4 pb-4 md:px-0">
        <div className={containerClasses}>
          <div className={widthClasses}>
            <h2 className={headlineCLasses}>Redeem Your Gift Code</h2>
            <p className={paragraphClasses}>
              Enter the code that you were gifted and fill out the form to activate your membership!
            </p>
            {alert && <Alert {...alert} />}
            <Form defaults={{ state: 'South Carolina' }} submitForm={submitForm} form={redeemForm} />
          </div>
        </div>
      </section>
    </>
  );
};

export default RedeemGiftCode;
