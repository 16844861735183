import React from "react";
import SEO from "../../seo";
import AdminHeader from '../../nav/admin-header';
import RollingData from '../../redemptions/rolling-data';

const RollingRedemptions = () => {
  const breadcrumbs = [
    { label: 'Dashboard', href: '/app/dashboard' },
    { label: 'Redemptions', href: '/app/admin/redemptions' },
    { label: 'Rolling Redemptions', href: '/app/admin/rolling-redemptions' },
  ];

  return (
    <>
      <SEO title="Rolling Redemptions" />
      <AdminHeader title="Rolling Redemptions" breadcrumbs={breadcrumbs} />

      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 md:px-48 lg:px-64 py-12 items-center justify-center flex-col">
          <RollingData />
        </div>
      </section>
    </>
  );
};

export default RollingRedemptions;
