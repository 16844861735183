import React, { useState, useEffect } from "react";
import * as yup from 'yup';
import { Link, navigate } from 'gatsby';
import moment from 'moment';
import queryString from 'query-string';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Alert from '../../alerts/alert';
import Loader from '../../loader';
import SEO from "../../seo";
import { apiUrl, getRequest, postRequest } from '../../../utils/request';
import { translateRoleIdToName } from '../../../utils/auth';
import AdminHeader from '../../nav/admin-header';
import Table from '../../common/table';
import Pagination from "../../common/pagination";
import Form from '../../form/form';

function buildDownloadUrl(token) {
  let concatenated = `${apiUrl}/admin/users/csv?token=${token}`;
  return concatenated.replace('/api/admin', '/admin');
}

const filterForm = {
  order: [
    'phone',
    'email',
    'verified',
    'role_id',
    'submit',
  ],
  fields: {
    role_id: {
      schema: yup.string().trim(),
      label: "Role",
      type: "select",
      options: [
        { value: "1", label: translateRoleIdToName(1) },
        { value: "2", label: translateRoleIdToName(2) },
        { value: "3", label: translateRoleIdToName(3) },
      ],
      selectText: "All",
    },
    verified: {
      schema: yup.string().trim(),
      label: "Verified Status",
      type: "select",
      options: [
        { value: "1", label: "Verified Only" },
        { value: "0", label: "All Users" },
      ],
      // selectText: "Verified (Default)",
    },
    phone: {
      schema: yup.string().trim(),
      customValidation: (field, value, values) => {
        const msg = "You must provide a valid US mobile phone number.";
        if (!value) {
          return null;
        }
        const phoneNumber = parsePhoneNumberFromString(value, 'US');
        return !phoneNumber || !phoneNumber.isValid() ? msg : null;
      },
      label: "Phone",
      type: "phone",
    },
    email: {
      schema: yup.string().email().max(255).trim(),
      label: "Email",
      type: "email",
    },
    submit: {
      type: 'button',
      submit: true,
      text: "Search",
      color: "cool-gray",
      block: true,
    },
  },
};

const Users = () => {
  const [mounted, setMounted] = useState(false);
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(null);
  const [lastPage, setLastPage] = useState(1);
  const [lastParams, setLastParams] = useState('');
  const [searchValues, setSearchValues] = useState({ page: 1 });

  const maybeUpdateSearchValues = (params, force) => {
    const searchParams = params || {};
    const paramsAsString = queryString.stringify(searchParams);
    if (force || paramsAsString !== lastParams) {
      setSearchValues({ ...searchParams });
      setLastParams(paramsAsString);
    }
  };

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    maybeUpdateSearchValues(params, true);
    setMounted(true);
  }, []);

  useEffect(() => {
    if (window) {
      const params = queryString.parse(window.location.search);
      maybeUpdateSearchValues(params);
    }
  });

  useEffect(() => {
    fetchUsers();
  }, [searchValues]);

  const updateParams = (params) => {
    navigate(`/app/admin/users/?${queryString.stringify(params)}`);
  }

  const switchPage = (num) => {
    const params = {
      ...searchValues,
      page: 1 * num,
    };
    updateParams(params);
  }

  const handleSearch = (data, setErrors) => {
    const params = {
      ...data,
      page: 1,
    };
    updateParams(params);
    setErrors({});
  }

  const fetchUsers = async function () {
    if (!mounted) {
      return;
    }
    setLoading(true);
    // console.log(page, 'the page...');
    const params = {
      ...searchValues,
      page,
    };
    const applicationUrl = `${apiUrl}/admin/users?${queryString.stringify(params)}`;
    try {
      const result = await getRequest(applicationUrl);
      // Format the results for the table
      if (result && result.data) {
        const columns = [
          "Name",
          "Email",
          "Phone",
          "Role",
          "Date",
          "Actions",
        ];
        // console.log(result, 'result');
        const { last_page } = result;
        setLastPage(last_page || 1);
        const rows = result.data.map(item => {
          const { id, name, role, email, phone, updated_at: updatedAt } = item;
          return {
            id,
            items: [
              name,
              email,
              phone,
              translateRoleIdToName(role.id),
              moment(updatedAt).format('M/D/YY'),
              <>
                <Link className="text-indigo-600 hover:text-indigo-900 mx-2" to={`/app/admin/user/${id}`}>View</Link>
                &middot;
                <Link className="text-indigo-600 hover:text-indigo-900 ml-2" to={`/app/admin/user/${id}/edit`}>Edit</Link>
              </>,
            ],
          };
        });
        setUsers({ columns, rows });
      }
    } catch (err) {
      console.log(err, 'err');
      setAlert({
        type: 'error',
        body: "An error occurred, please reload the page.",
      });
    }
    setLoading(false);
  };

  const downloadUsers = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Grab an authcode
    try {
      const tokenResult = await postRequest(`${apiUrl}/admin/one-time-download-token`);
      const { token } = tokenResult;
      window.open(buildDownloadUrl(token), '_blank');
    } catch (err) {
      console.log(err, 'err');
      setAlert({
        type: 'error',
        body: "An error occurred, please reload the page.",
      });
    }
    setLoading(false);
  }


  // console.log(upcoming, 'upcom')
  const breadcrumbs = [
    { label: 'Dashboard', href: '/app/dashboard' },
    { label: 'Users', href: '/app/admin/users' },
  ];

  const page = searchValues && searchValues.page ? 1 * searchValues.page : 1;

  return (
    <>
      <SEO title="Users" />
      <AdminHeader title={`Users`} breadcrumbs={breadcrumbs} />

      {alert && <Alert {...alert} />}
      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 py-12 items-center justify-center flex-col">
          <div className="flex w-full text-left flex-wrap -mx-2">
            <div className="w-full lg:w-4/5 px-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-5">Users &middot; <button onClick={downloadUsers} className="font-bold underline">Download</button></h3>
              {loading && <Loader />}
              {!loading && !users && <p>There are no users to display.</p>}
              {!loading && users && <Table {...users} />}
              {!loading && users && <Pagination currentPage={page} totalPages={lastPage} switchPage={switchPage} />}
            </div>
            <div className="w-full lg:w-1/5 px-2 mt-12 lg:mt-0">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-5">Search</h3>
              <div className="bg-white shadow p-4 rounded  border border-gray-100">
                {mounted && <Form defaults={searchValues} submitForm={handleSearch} form={filterForm} />}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Users;
