import React, { useEffect } from "react";
import { Router } from "@reach/router";
import { pageview } from '../../utils/scripts';
import Layout from "../../components/layout";
import PrivateRoute from "../../components/private-route";
import Dashboard from '../../components/private/dashboard';
import Checkout from "../../components/private/checkout";
import RedeemGiftCode from "../../components/private/redeem-gift-code";
import AdminTopReferrersPage from '../../components/private/admin/top-referrers';
import AdminUsersPage from '../../components/private/admin/users';
import AdminUserPage from '../../components/private/admin/user';
import AdminUserEditPage from '../../components/private/admin/user-edit';
import CouponPage from "../../components/coupons/coupon-page";
import AdminRedemptionsPage from '../../components/private/admin/redemptions';
import AdminRollingRedemptionsPage from '../../components/private/admin/rolling-redemptions';
import PastDue from "../../components/private/user/past-due";


const App = () => {
  useEffect(() => {
    pageview();
  }, []);
  return (
    <Layout>
      <Router>
        <PrivateRoute path="/app/dashboard" component={Dashboard} />
        <PrivateRoute path="/app/checkout" component={Checkout} />
        <PrivateRoute path="/app/redeem-gift-code" component={RedeemGiftCode} />
        <PrivateRoute path="/app/past-due" component={PastDue} />


        <PrivateRoute claims="admin" path="/app/admin/users" component={AdminUsersPage} />
        <PrivateRoute claims="admin" path="/app/admin/user/:id" component={AdminUserPage} />
        <PrivateRoute claims="admin" path="/app/admin/user/:id/edit" component={AdminUserEditPage} />
        <PrivateRoute claims="admin" path="/app/admin/top-referrers" component={AdminTopReferrersPage} />
        <PrivateRoute claims="admin" path="/app/admin/rolling-redemptions" component={AdminRollingRedemptionsPage} />
        <PrivateRoute claims="admin|manager" path="/app/admin/redemptions" component={AdminRedemptionsPage} />

        <CouponPage path="/app/coupon/:code" />

      </Router>
    </Layout>
  );
}

export default App